import { render, staticRenderFns } from "./SetUser.vue?vue&type=template&id=60f7fc32&scoped=true"
import script from "./SetUser.vue?vue&type=script&lang=js"
export * from "./SetUser.vue?vue&type=script&lang=js"
import style0 from "./SetUser.vue?vue&type=style&index=0&id=60f7fc32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f7fc32",
  null
  
)

export default component.exports